import React, {useEffect, useState} from "react";
import {DateHelper} from "@bryntum/scheduler/scheduler.umd";
import {EVENT_TYPE, RESOURCES} from "../constants";
import {
    Box,
    Button, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle,
    FormControl, FormControlLabel, Grid,
    IconButton, InputBase,
    InputLabel,
    Link,
    ListSubheader,
    MenuItem, Paper,
    Select,
    TextField
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CalendarDatePicker from "./CalendarDatePicker";
import TimeDropdown from "./TimeDropdown";
import './SCPopup.scss';
import {isEmptyArray, isExists, isNotNull} from "../utils";
import {ColorPicker} from 'mui-color';
import {
    getEventColorFromConfigs,
    setTimeToDate,
    parseDate,
    parseDate2,
    replaceOrAppendDate
} from "../calendars/bryntum_helpers";
import {getProjectTeamsByGame, getTemplatesByGame} from "../redux/calendar-config/actions";
import {useSelector} from "react-redux";
import {
    decodeResourceId,
    getSubEventDate,
    getSubEventsMapByResourceId,
} from "../calendars/bryntum_configs/compositeEventUtils";
import {Title} from "@mui/icons-material";
import EndTimeDropdown from "./EndTimeDropDown";
import LabelSelector from "./LabelSelector";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 180,
        },
    },
};

const SECTION_NAMES_EVENTS = [
    '0KHQutC40L0g0LPQvtGA0L7QtNCw',
    '0J3QvtCy0YvQtSDRgNCw0LnQvtC90Ysg0LPQvtGA0L7QtNCw',
    '0JjQvdC00LjQstC40LTRg9Cw0LvRjNC90YvQtSDRgdC+0LHRi9GC0LjRjw==',
    '0KLRg9GA0L3QuNGA0Ys=',
    '0J/QvtCy0YLQvtGA0Y/QtdC80YvQtSDRgdC+0LHRi9GC0LjRjw==',
    'QmF0dGxlIFBhc3M=',
    '0JTQtdC60L7RgNC40YDQvtCy0LDQvdC40LU=',
    '0KHQvtCx0YvRgtC40Y8g0YEg0YPRgNC+0LLQvdGP0LzQuCAoMjAt0LrQuCDQuCDQvNCw0YDQsNGE0L7QvdGLKQ==',
    '0JDQutGG0LjQuCDQuCDRgdC60LjQtNC60Lg=',
    '0JDQutGG0LjQuCDQtNC70Y8g0YHQvtCx0YvRgtC40Lk=',
    '0KfQsNGB0L7QstC+0Lkg0YHQtdC50YQ=',
    'TWF0Y2gtMw==',
    "0JLRgNC10LzQtdC90L3Ri9C5INCz0L7RgNC+0LTQsA=="
]

export default function Popup(props) {

    const templates = useSelector((state) => state.calendarConfig.templates)
    const projectTeams = useSelector((state) => {
        return state.calendarConfig ? state.calendarConfig.projectTeams : null
    })
    const [projectVersionSelected, setProjectVersionSelected] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [editSection, setEditSection] = useState(false);

    useEffect(() => {
        setPopupState((prevState) => ({
            ...prevState,
            linkEditDataPress: false,
        }));

        if (props.eventRecord.version && props.eventRecord.version !== '') {
            setProjectVersionSelected(true);
        }
    }, [])

    function findTeamByGid() {
        if (props.eventRecord.teamProject !== null && props.eventRecord.teamProject !== '') {
            let result = projectTeams.filter(x => x.projectGid === props.eventRecord.teamProject)[0]
            if (result) {
                return result.teamName;
            }
        }
    }

    const useTime = props.eventRecord.type === EVENT_TYPE.EVENT ||
        props.eventRecord.type === EVENT_TYPE.RELEASE ||
        props.eventRecord.type === EVENT_TYPE.MATCH3 ||
        props.eventRecord.type === EVENT_TYPE.ASO ||
        props.eventRecord.type === EVENT_TYPE.AB_TEST ||
        props.eventRecord.type === EVENT_TYPE.AB_TEST_COMMENT;


    const subsectionRecords = props.eventStore.getEventsForResource(props.eventRecord.data.resourceId)

    const convertBracketsToString = (input) => {
        // Регулярное выражение для нахождения групп в скобках
        const regex = /\(([^)]+)\)/g;
        const matches = input.match(regex);

        if (matches && matches.length >= 2) {
            // Берем первую группу и убираем скобки
            const firstGroup = matches[0].replace(/[()]/g, '');

            // Преобразуем в CamelCase
            const camelCase = firstGroup
                .split(' ')
                .map((word, index) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join('');

            return camelCase;
        } else {
            return false;
        }
    }


    const generateEventIdIndividual = (record) => {
        const closestRecord = findClosestDate(subsectionRecords, record);
        const dateStr = parseDate(record.startDate);
debugger
        let patterns = [
            { pattern: 'Змеи и Лестницы:', code: 'SLE_' },
            { pattern: 'PvE:', code: 'TCD_' },
            { pattern: 'PVE:', code: 'TCD_' },

            { pattern: 'Gems Tournament:', code: 'SB_TO_Gems_' },
            { pattern: 'Champions Tournament:', code: 'TOChampions_' },
            { pattern: 'Weekly Contest:', code: 'WC_' },
            { pattern: 'Lava Quest:', code: 'SB_LQ_' },

            { pattern: 'Rolling:', code: 'MSO_' },
            { pattern: 'Conveyor:', code: 'CNV_' },
            { pattern: 'DOO:', code: 'DOO_', brackets: true },
            { pattern: 'BF: SeasonShop_', code: 'BF_SeasonShop_', brackets: true },
            { pattern: 'BF:', code: 'BF_', brackets: true },

            { pattern: 'Merge:', code: '_MRG_', brackets: true },
        ];

        for (let index in patterns) {
            if (record.name.indexOf(patterns[index].pattern) >= 0) {
                let nameBlocks = record.name.split(patterns[index].pattern);
                if (nameBlocks.length < 2) return false;

                // Сохранить первую часть имени до первой скобки и убрать пробелы
                let baseName = nameBlocks[1].split('(')[0].trim().replace(/\s+/g, '');

                if (patterns[index].hasOwnProperty('brackets') && patterns[index].brackets === true) {
                    let bracketsText = convertBracketsToString(nameBlocks[1]);
                    if (bracketsText !== false) baseName = baseName + '_' + bracketsText;
                }

                return dateStr + patterns[index].code + baseName;
            }
        }

        return dateStr + '_' + record.eventId.substr(9)

        return false;
    }

    const generateEventId20 = (record, code) => {
        //События с уровнями 20ки
        const closestRecord = findClosestDate(subsectionRecords, record)
        if(props.eventRecord.data.eventId != undefined && props.eventRecord.data.eventId.length > 0) {
            return props.eventRecord.data.eventId
        }

        if(closestRecord == null || !closestRecord.data.hasOwnProperty('eventId') || closestRecord.data.eventId==undefined) return false
        if(closestRecord.eventId.indexOf(code) < 0) return false

        const eventIdParts = closestRecord.eventId.split(code)
        const dateStr = parseDate(record.startDate)
        return dateStr + code + eventIdParts[1]
    }


    const generateEventIdBP = (record) => {
        const dateStr = parseDate(record.startDate)

        const nameParts = record.name.split('№')
        if(nameParts.length<2) return false;
        const namePartsNumber = nameParts[1].trim().split(':')
        if(namePartsNumber.length<2) return false;

        const baseName = namePartsNumber[1].trim().split(' ')
        if(baseName.length<2) return false;

        return dateStr + '_' + namePartsNumber[0] + '_BPR_' + baseName[0]
    }

    const generateEventId = (record) => {

        console.log('generateEventId')
        console.log(record)


        if(record.name == '') {
            return false
        }
        switch (record.resourceId) {
            case btoa('Battle Pass'):
                return generateEventIdBP(record)
                break;
            //case btoa('Временный города'):
            case "0JLRgNC10LzQtdC90L3Ri9C5INCz0L7RgNC+0LTQsA==":
                if(record.name.toLocaleLowerCase().indexOf('merge') >= 0) {
                    return generateEventIdIndividual(record)
                } else {
                    if(record.name.toLocaleLowerCase().indexOf('TT:') >= 0)
                        return generateEventId20(record, 'TT')

                    if(record.name.toLocaleLowerCase().indexOf('DS:') >= 0)
                        return generateEventId20(record, 'DS:')

                }
                break;
            //case btoa('События с уровнями (20-ки и марафоны)'):
            case "0KHQvtCx0YvRgtC40Y8g0YEg0YPRgNC+0LLQvdGP0LzQuCAoMjAt0LrQuCDQuCDQvNCw0YDQsNGE0L7QvdGLKQ==":
                return generateEventId20(record, 'SETP')
                break;
            //case btoa('Индивидуальные события'):
            case "0JjQvdC00LjQstC40LTRg9Cw0LvRjNC90YvQtSDRgdC+0LHRi9GC0LjRjw==":
                if(record.name.indexOf('Декорирование') >= 0) return generateEventId20(record, 'DE')
                return generateEventIdIndividual(record)
                break;
            //case btoa('Турниры'):
            case "0KLRg9GA0L3QuNGA0Ys=":
                if(record.name.toLocaleLowerCase().indexOf('reskin') == -1) return ""
                return generateEventIdIndividual(record)
                break;
            //Акции и скидки
            case "0JDQutGG0LjQuCDQtNC70Y8g0YHQvtCx0YvRgtC40Lk=":
            case "0JDQutGG0LjQuCDQuCDRgdC60LjQtNC60Lg=":
                if(record.name.toLocaleLowerCase().indexOf('risk or riches') >= 0) {
                    const dateStr = parseDate(record.startDate)
                    return dateStr + 'SERisk'
                } else {
                    return generateEventIdIndividual(record)
                }
                break;
            default: return false;
        }

    }

    const [popupState, setPopupState] = useState({
        name: '',
        useTimeInStartDate: useTime,
        useTimeInEndDate: useTime,
        note: props.eventRecord.status === "create" ? "" : props.eventRecord.note,
        link: props.eventRecord.status === "create" ? null : props.eventRecord.link,
        linkEditDataPress: false,
        editedLink: props.eventRecord.status === "create" ? null : props.eventRecord.link,
        ...props.eventRecord.data,
        disabledEditLinkButton: false,
        teamProject: props.eventRecord.status === "update" && props.eventRecord.teamProject ? props.eventRecord.teamProject : '',
        version: props.eventRecord.status === "update" ? props.eventRecord.version : "",
        releaseSubmitIndicator: isExists(props.eventRecord.releaseSubmitIndicator) ? props.eventRecord.releaseSubmitIndicator : true,
        template : getAsoDefaultValue(props),
        eventId: props.eventRecord.data.eventId == undefined ? '' : props.eventRecord.data.eventId
    });


    useEffect(() => {
        const eventId = generateEventId({ resourceId: popupState.resourceId, name: popupState.name, startDate: popupState.startDate, eventId: popupState.eventId });
debugger
        if(eventId!==false && eventId != "") {
            setPopupState((prevState) => ({
                ...prevState,
                eventId: eventId
            }));
        }

        if(popupState.type == EVENT_TYPE.EVENT) {

            const nameWithDate = replaceOrAppendDate(popupState)
            setPopupState((prevState) => ({
                ...prevState,
                name: nameWithDate
            }));
        }

    }, [popupState.name, popupState.startDate]);

    function dataChangedHandler({target}) {

        let newPopupState = {
            ...popupState,
            [target.name]: target.value
        }

        // if(target.name == "name" && (popupState.eventId == "" || popupState.eventId == undefined)) {
        //     const eventId = generateEventId({ name: popupState.name, startDate: popupState.startDate });
        //     //newPopupState['eventId'] = eventId
        // }

        //
        // if(target.name == "name" && (popupState.eventId == "" || popupState.eventId == undefined)) {
        //     const eventId = generateEventId({name: target.value, startDate: popupState.startDate})
        //     newPopupState.eventId = eventId
        // }


        setPopupState(newPopupState);

        if (target.name === "version" && (target.value !== '' && target.value !== null)) {
            setProjectVersionSelected(true);
        } else if (target.name === "version" && (target.value === '' || target.value === null)) {
            setProjectVersionSelected(false);
        }


    }

    function changeLabel(name, color) {
        setPopupState((prevState) => ({
            ...prevState,
            label: {name, color}
        }))
    }

    function changeColor(color) {
        setPopupState((prevState) => ({
            ...prevState,
            eventColor: "#" + color.hex
        }));
    }

    function endDateChangeHandler(endDate) {
        let diff = DateHelper.diff(popupState.startDate, endDate, 'd');
        const endDateWithTime = new Date(endDate.setHours(popupState.endDate.getHours()));
        setPopupState((prevState) => ({
            ...prevState,
            duration: diff,
            endDate: endDateWithTime
        }));
    }

    const changeEndTime = (time) => {
        // let newStartDate = setTimeToDate(popupState.startDate.toString(), time)
        debugger
        let newEndDate = setTimeToDate(popupState.endDate.toString(), time)
        let diff = DateHelper.diff(popupState.startDate, newEndDate, 'd');
        setPopupState((prevState) => ({
            ...prevState,
            useTimeInEndDate: true,
            // startDate: newStartDate,
            endDate: newEndDate,
            duration: diff
        }));
    }

    function startDateChangeHandler(startDate, game) {
        let diff
        if (popupState.type === EVENT_TYPE.COMMUNITY) {
            if (game && game !== "SC") {
                diff = DateHelper.diff(startDate, popupState.endDate, 'd');
            }
            diff = 1;
        } else {
            diff = DateHelper.diff(startDate, popupState.endDate, 'd');
        }
        const startDateWithTime = new Date(startDate.setHours(popupState.startDate.getHours()));
        setPopupState((prevState) => ({
            ...prevState,
            duration: diff,
            startDate: startDateWithTime
        }));
    }

    const changeStartTime = (time) => {
        let newStartDate = setTimeToDate(popupState.startDate.toString(), time)
        let diff = DateHelper.diff(newStartDate, popupState.endDate, 'd');
        setPopupState((prevState) => ({
            ...prevState,
            useTimeInStartDate: true,
            startDate: newStartDate,
            duration: diff
        }));
    }

    const openConfirmationDialogOrSave = () => {
        if (props.popupData.type === EVENT_TYPE.AB_TEST && !projectVersionSelected) {
            setConfirmDialogOpen(true)
        } else {
            handleClickSave();
        }
    }

    const handleClickSave = () => {
        fillCompositeEventData(popupState);
        setPopupState((prevState) => ({
            ...prevState,
            linkEditDataPress: false
        }));
        props.onSave(popupState)
    }

    function saveButtonStatus() {
        if(popupState.type==EVENT_TYPE.EVENT && popupState.template == undefined )
            return true

        if ((popupState.eventId == '' || popupState.eventId == undefined)
            && popupState.resourceId == "0KHQvtCx0YvRgtC40Y8g0YEg0YPRgNC+0LLQvdGP0LzQuCAoMjAt0LrQuCDQuCDQvNCw0YDQsNGE0L7QvdGLKQ=="
            )
            return true

        if (props.popupData.type === EVENT_TYPE.AB_TEST && popupState.teamProject === '' && props.eventRecord.status === 'create') {
            return true
        }
        if (popupState.name !== '' && popupState.duration >= 1) {
            return false
        }
        return true
    }

    function getMiniTeamsSelector(popupType) {
        if (popupType === EVENT_TYPE.AB_TEST) {
            return <FormControl style={{width: '100%', marginBottom: 5, marginTop: 10}} variant="standard">
                <InputLabel id="event-template">Choose team</InputLabel>
                <Select
                    labelid="test-team"
                    id="event-name"
                    name="teamProject"
                    value={popupState.teamProject}
                    onChange={(event) => dataChangedHandler(event)}
                    MenuProps={MenuProps}>
                    {isExists(getProjectTeamsByGame(projectTeams, props.game)) &&
                        getProjectTeamsByGame(projectTeams, props.game).map((projectTeam) => (
                            <MenuItem key={projectTeam.projectGid} value={projectTeam.projectGid}>
                                {projectTeam.teamName}
                            </MenuItem>
                        ))}
                    {props.game === 'CM' && isExists(getProjectTeamsByGame(projectTeams, 'CM_additional_teams')) &&
                        getProjectTeamsByGame(projectTeams, 'CM_additional_teams').map((projectTeam) => (
                            <MenuItem key={projectTeam.projectGid} value={projectTeam.projectGid}>
                                {projectTeam.teamName}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        } else if (popupType === EVENT_TYPE.EVENT && props.game === 'CM') {
            return <>
                <FormControl style={{width: '100%', marginBottom: 5, marginTop: 10}} variant="standard">
                    <InputLabel id="event-template">Choose team</InputLabel>
                    <Select
                        labelid="test-team"
                        id="event-name"
                        name="teamProject"
                        value={popupState.teamProject}
                        onChange={(event) => dataChangedHandler(event)}
                        MenuProps={MenuProps}>
                        {isExists(getProjectTeamsByGame(projectTeams, 'CM_additional_teams')) &&
                            getProjectTeamsByGame(projectTeams, 'CM_additional_teams').map((projectTeam) => (
                                <MenuItem key={projectTeam.projectGid} value={projectTeam.projectGid}>
                                    {projectTeam.teamName}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </>
        }

    }

    function projectVersionFormatIsValid(val) {
        let regexp = /^\d+\.\d+\.\d+$/;
        return regexp.test(val)
    }

    function getIndicatorCheckBox(popupType) {
        if (popupType === EVENT_TYPE.ASO) {
            return (
                <FormControlLabel
                                  control={
                                      <Checkbox
                                          checked={popupState.releaseSubmitIndicator}
                                          onChange={(event) => {
                                              setPopupState((prevState) => ({
                                                  ...prevState,
                                                  releaseSubmitIndicator: event.target.checked,
                                              }));
                                          }}
                                          />
                                  }
                                  label="Добавить событие в раздел Сабмит/Релиз?"
                />
            )
        }
    }

    function getAsoDefaultValue(props) {
        if (props.popupData.type === EVENT_TYPE.ASO) {
            if (isExists(getTemplatesByGame(templates, props.game, props.popupData.type).templateGidsByName[props.resourceRecord.data.name])) {
                let result = getTemplatesByGame(templates, props.game, props.popupData.type);
                return Object.entries(result.templateGidsByName[props.resourceRecord.data.name])[0][0]
            }
        }
        return props.eventRecord.data.template;
    }

    function findClosestDate(objects, record) {
        const targetDate = record.startDate
        const target = new Date(targetDate).getTime();

        const validObjects = objects.filter(obj => new Date(obj.startDate).getTime() < target && obj.name.replace(/\s*\(.*?\)\s*/g, '').trim() == record.name.replace(/\s*\(.*?\)\s*/g, '').trim());

        if (validObjects.length === 0) {
            return null; // или выберите подходящее значение для случая, когда нет подходящих объектов
        }

        return validObjects.reduce((closest, obj) => {
            const currentStartDate = new Date(obj.startDate).getTime();
            const closestStartDate = new Date(closest.startDate).getTime();

            return Math.abs(currentStartDate - target) < Math.abs(closestStartDate - target) ? obj : closest;
        }, validObjects[0]);
    }

    function getPopupBodyByType() {
debugger
        const popupType = props.popupData.type
        let element;
        switch (popupType) {
            case EVENT_TYPE.EVENT:
            case EVENT_TYPE.ASO:
            case EVENT_TYPE.MATCH3:
            case EVENT_TYPE.COMMUNITY:
            case EVENT_TYPE.AB_TEST:
                debugger
                element = <>
                    <Grid container spacing={2}>
                        <Grid item xs={popupType === EVENT_TYPE.AB_TEST ? 8 : 12}>
                            <TextField
                                name="eventId"
                                label="Event ID"
                                required={true}
                                variant="standard"
                                onChange={(event) => dataChangedHandler(event)}
                                value={popupState.eventId}
                                fullWidth
                            />
                        </Grid>
                        {popupType === EVENT_TYPE.AB_TEST &&
                            <Grid item xs={4}>
                                <TextField
                                    name="version"
                                    label="Project version"
                                    helperText={!projectVersionFormatIsValid(popupState.version) && props.game === "BA" ? "Version must be in format 1.1.1" : ""}
                                    error={!projectVersionFormatIsValid(popupState.version) && props.game === "BA"}
                                    variant="standard"
                                    onChange={(event) => dataChangedHandler(event)}
                                    value={popupState.version}/>
                            </Grid>}

                    </Grid>

                    {popupType === EVENT_TYPE.AB_TEST&&props.eventRecord.status === "update" &&
                        <FormControl style={{width: '100%', marginBottom: 5, marginTop: 10}} variant="standard">
                            <InputLabel id="eventresourceId">Секция</InputLabel>
                            <Select
                                disabled={!editSection}
                                labelid="eventresourceId"
                                id="eventresourceId"
                                name="resourceId"
                                value={popupState.resourceId}
                                onChange={(event) => dataChangedHandler(event)}
                                MenuProps={MenuProps}>
                                <MenuItem key={"QS9CINGC0LXRgdGC0Ys="} value={"QS9CINGC0LXRgdGC0Ys="}>
                                    A/B тесты
                                </MenuItem>
                                <MenuItem key={"QS9CINGC0LXRgdGC0Ysg0L3QvtCy0YvQtSDQuNCz0YDQvtC60Lg="} value={"QS9CINGC0LXRgdGC0Ysg0L3QvtCy0YvQtSDQuNCz0YDQvtC60Lg="}>
                                    A/B тесты новые игроки
                                </MenuItem>
                                <MenuItem key={"0JrQsNC70LjQsdGA0L7QstC60Lgg0YPRgNC+0LLQvdC10Lk="} value={"0JrQsNC70LjQsdGA0L7QstC60Lgg0YPRgNC+0LLQvdC10Lk="}>
                                    Калибровки уровней
                                </MenuItem>

                            </Select>
                            {!editSection&&<div onClick={() => {setEditSection(true)}} style={{"color":"#00a","textDecoration":"underlined"}}>изменить секцию</div>}

                        </FormControl>}
                    {popupType === EVENT_TYPE.EVENT&&props.eventRecord.status === "update" &&
                        <FormControl style={{width: '100%', marginBottom: 5, marginTop: 10}} variant="standard">
                            <InputLabel id="eventresourceId">Секция</InputLabel>
                            <Select
                                labelid="eventresourceId"
                                disabled={!editSection}
                                id="eventresourceId"
                                name="resourceId"
                                value={popupState.resourceId}
                                onChange={(event) => dataChangedHandler(event)}
                                MenuProps={MenuProps}>
                                {SECTION_NAMES_EVENTS.map(item => <MenuItem key={item} value={item}>
                                    {decodeURIComponent(
                                        Array.from(atob(item))
                                            .map((char) => `%${char.charCodeAt(0).toString(16).padStart(2, '0')}`)
                                            .join('')
                                    )}
                                </MenuItem>)}
                            </Select>
                            {!editSection&&<div onClick={() => {setEditSection(true)}} style={{"color":"#00a","textDecoration":"underlined"}}>изменить секцию</div>}
                        </FormControl>}

                    {(props.eventRecord.status === "create"
                            || (props.eventRecord.status === "update" && props.game === 'SC' && !props.eventRecord.data.link)
                            || (props.eventRecord.status === "update" && props.game === 'CM')
                        ) &&
                        <FormControl style={{width: '100%', marginBottom: 5, marginTop: 10}} variant="standard">

                            {popupType !== EVENT_TYPE.COMMUNITY &&
                                isExists(getTemplatesByGame(templates, props.game, popupType==EVENT_TYPE.AB_TEST?EVENT_TYPE.RELEASE:popupType)) &&
                                getTemplatesByGame(templates, props.game, popupType==EVENT_TYPE.AB_TEST?EVENT_TYPE.RELEASE:popupType)?.templateGidsByName[props.resourceRecord.data.name]!=null &&
                                <>
                                    <InputLabel id="event-template">Choose template *</InputLabel>
                                    <Select
                                        required={true}
                                        labelid="event-template"
                                        id="event-name"
                                        name="template"
                                        value={popupState.template}
                                        onChange={(event) => dataChangedHandler(event)}
                                        MenuProps={MenuProps}>
                                        {isExists(getTemplatesByGame(templates, props.game, popupType==EVENT_TYPE.AB_TEST?EVENT_TYPE.RELEASE:popupType).templateGidsByName[props.resourceRecord.data.name]) &&
                                            Object.entries(getTemplatesByGame(templates, props.game, popupType==EVENT_TYPE.AB_TEST?EVENT_TYPE.RELEASE:popupType).templateGidsByName[props.resourceRecord.data.name]).map(([id, name]) => (
                                                name === "subHeader" ?
                                                    <ListSubheader key={id} disableSticky>{id}</ListSubheader> :
                                                    <MenuItem key={id} value={id}>
                                                        {name}
                                                    </MenuItem>
                                            ))}
                                    </Select>
                                </>
                            }
                            {getIndicatorCheckBox(popupType)}
                            {getMiniTeamsSelector(popupType)}

                        </FormControl>}
                    <Dialog
                        open={confirmDialogOpen}
                    >
                        <DialogTitle id="alert-dialog-title" className="sync_popup__blue_gradient"
                                     sx={{textAlign: "center", color: "white"}}>Подтвердите создание</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" sx={{textAlign: "center", pt: 3}}>
                                Хотите продолжить без указания версии проекта?
                                <br/>
                            </DialogContentText>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box display="flex" justifyContent="flex-end" mr={1}>
                                        <Button variant="contained" color="primary"
                                                onClick={() => {
                                                    setConfirmDialogOpen(false);
                                                    handleClickSave();
                                                }}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box ml={1}>
                                        <Button variant="contained" color="secondary"
                                                onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
                                    </Box>

                                </Grid>
                            </Grid>
                        </DialogContent>

                    </Dialog>
                </>
        }
        return element;
    }


    function getStartDateStyles() {
        const styles = {
            marginRight: 1,
            marginTop: 2
        }
        if (props.popupData.hasEndDate) {
            styles.width = props.popupData.hasTime ? "32%" : "49%"
        } else {
            styles.width = props.popupData.hasTime ? "84%" : "100%"
        }
        return styles;
    }

    function getEventColor() {
        if (isExists(popupState.eventColor)) {
            return popupState.eventColor
        } else {
            return isNotNull(getEventColorFromConfigs(props.eventRecord, props.resourceRecord)) ? getEventColorFromConfigs(props.eventRecord, props.resourceRecord) : undefined;
        }
    }

    function handleLinkEdit() {
        setPopupState((prevState) => ({
            ...prevState,
            linkEditDataPress: true
        }));
    }

    function handleLinkSave() {
        setPopupState((prevState) => ({
            ...prevState,
            link: popupState.editedLink,
            linkEditDataPress: false
        }));
    }

    function handleLinkChange(event) {
        setPopupState({
            ...popupState,
            editedLink: event.target.value,
            disabledEditLinkButton: !isLinkToAsanaOrNull(event.target.value)
        })
    }

    function isLinkToAsanaOrNull(link) {
        if (link == null) {
            return true;
        }
        return link.match(/^https:\/\/app\.asana\.com\/\d+\/\d+\/\d+($|\/.*)$/) != null;
    }

    const fillCompositeEventData = (eventRecord) => {
        if ((decodeResourceId(eventRecord.resourceId) === RESOURCES.MATCH3) && eventRecord.template) {
            const subEventMap = getSubEventsMapByResourceId(RESOURCES.MATCH3);
            if (!eventRecord.isParentCompositeEvent) {
                eventRecord.isParentCompositeEvent = true;
                const newEndDateForParentEvent = subEventMap.get('. Отключить AB-тест');
                eventRecord.endDate = getSubEventDate(new Date(eventRecord.startDate.getTime() + ((newEndDateForParentEvent + 1) * 24 * 60 * 60 * 1000)));
                eventRecord.duration = newEndDateForParentEvent + 1;
            }
        }
    }

    return (
        <Box className='popup-mask'>
            <Box className='popup'>
                <header>
                    {popupState.linkEditDataPress ?
                        <Paper
                            sx={{p: '2px 4px', display: 'flex', alignItems: 'center'}}
                        >
                            <InputBase
                                name={"link"}
                                fullWidth
                                onChange={handleLinkChange}
                                style={{marginBottom: 10}}
                                value={popupState.editedLink}
                            />
                            <IconButton
                                type="button" sx={{p: '10px'}}
                                disabled={popupState.disabledEditLinkButton}
                                onClick={handleLinkSave}>
                                <DoneIcon/>
                            </IconButton>

                        </Paper>
                        :
                        <Box>
                            <Link
                                href={popupState.link}
                                target="_blank"
                                rel="noopener noreferrer">
                                {popupState.name}
                            </Link>
                            <IconButton onClick={handleLinkEdit}>
                                <EditIcon/>
                            </IconButton>
                        </Box>
                    }
                    <Box sx={{
                        display: popupState.disabledEditLinkButton ? "flex" : "none",
                        fontSize: "10px",
                        color: "red"
                    }}>
                        Invalid Asana link
                    </Box>
                </header>
                <article>
                    <TextField
                        name="name"
                        label="Event name"
                        variant="standard"
                        onChange={(e) => dataChangedHandler(e)}
                        value={popupState.name}
                        fullWidth
                        style={{marginBottom: 10}}/>
                    <Box sx={{mt: "20px", width: "100%"}}>
                        <ColorPicker disableTextfield={true}
                                     value={getEventColor()}
                                     onChange={(event) => changeColor(event)}/>
                    </Box>

                        <Box sx={{mt: "20px", width: "100%"}}>
                            <LabelSelector
                                popupState={popupState}
                                changeLabel={changeLabel}
                                labels={props.labelsConfig}
                            />
                        </Box>
                    
                    {props.popupData.type !== "default" && getPopupBodyByType()}
                    <CalendarDatePicker dateChangeHandler={startDateChangeHandler}
                                        label="Start"
                                        styles={getStartDateStyles}
                                        date={popupState.startDate}
                                        props={props}/>
                    {props.popupData.hasTime &&
                        <TimeDropdown startDate={popupState.startDate} changeStartTime={changeStartTime}
                                      style={props.popupData.hasEndDate ? {margin: "32px 15px 0 0"} : {margin: "32px 0 0 0"}}
                                      defaultTime={props.eventRecord.status === "create" ? props.resourceRecord.defaultTime : undefined}/>}
                    {props.popupData.hasEndDate &&
                        <CalendarDatePicker dateChangeHandler={endDateChangeHandler}
                                            label="End"
                                            styles={props.popupData.hasTime ? {
                                                "width": "32%",
                                                marginTop: 2
                                            } : {"width": "49%", marginTop: 2}}
                                            date={popupState.endDate}/>}
                    {props.popupData.hasTime &&
                        <EndTimeDropdown endDate={popupState.endDate} changeEndTime={changeEndTime}
                                         style={{margin: "0 0 0 15px"}}
                                         defaultTime={props.eventRecord.status === "create" ? props.resourceRecord.defaultTime : undefined}/>}

                </article>

                <footer>
                    <Button variant="contained" color="primary" disabled={saveButtonStatus()}
                            onClick={openConfirmationDialogOrSave}
                    >Save
                    </Button>
                    <Button variant="contained" color="secondary" onClick={props.onClose}>Cancel</Button>
                </footer>
            </Box>
        </Box>
    )
}