import ResourceColumn from "../../components/ResourceColumn";
import {
    COMMUNITY_TEMPLATES,
    EVENT_TYPE
} from "../../constants";
import {getEventIdFromParam, getEventName, hasText, isEmptyArray, isExists} from "../../utils";
import {labelsFeature} from "./labelsFeature";
import BryntumEvent from "../../BryntumEvent";
import {DateHelper, Mask} from "@bryntum/scheduler/scheduler.umd";
import {getAsanaSyncTasksInfo, getAsanaSyncTasksProgress, sendAddEvent} from "../../requests";
import {removeSyncResourcesAction} from "../../redux/sync-resources/actions";
import {INIT_WEBSOCKET} from "../../redux/websocket/websocketReducer";
import React from "react";
import store from "../../store";
import {
    addImportantDatesTimeRange,
    getCommunityTemplateInfoNameIfExist,
    getLiveOpsTemplateInfoNameIfExist,
    getRangeDayForCopyEvent, getTimeSpan, setStylesForNewEvents
} from "../bryntum_helpers";
import {getConfigByGame} from "../../redux/userReducer";
import {useSearchParams} from "react-router-dom";

let startDate = new Date();
startDate.setDate(startDate.getDate() - 60);
let endDate = new Date();
endDate.setDate(endDate.getDate() + 365);

let highlight = true,
    center    = false,
    animate   = 1000;

export const getCommonConfigs = (scheduler, user, game, searchParams) => {
    const eventFromUrl = getEventIdFromParam(searchParams);

    let schedulerConfig = {
        useInitialAnimation: false,
        enableEventAnimations: false,
        infiniteScroll: true,
        enableDeleteKey: false,
        eventCopyPasteFeature: {
            generateNewName: (eventRecord) => eventRecord.name
        },
        columns: [
            {
                type: 'resourceInfo',
                text: 'Belka Calendar v22.5',
                width: 250,
                showImage: false,
                showEventCount: false,
                editor: false,
                autoScaleThreshold: 0,
                renderer: ({record}) => <ResourceColumn record={record}
                                                        game={game}
                                                        config={getConfigByGame(user, game)}/>

            }
        ],
        presets: [
            {
                base: 'monthAndYear',
                id: 'monthAndYear'
            },
            {
                base: 'weekAndMonth',
                id: 'weekAndMonth'
            },
            {
                base: 'weekAndDay',
                id: 'weekAndDay0',
                tickWidth: 30,
                shiftUnit: 'day',
                headers: [
                    {
                        unit: 'month',
                        align: 'center',
                        dateFormat: 'MMM YYYY'
                    },
                    {
                        unit: 'd',
                        align: 'center',
                        dateFormat: 'dd',
                    },
                    {
                        unit: 'd',
                        align: 'center',
                        dateFormat: 'DD'
                    }
                ]
            },
            {
                base: 'weekAndDay',
                id: 'weekAndDay1',
                tickWidth: 60,
                shiftUnit: 'day',
                headers: [
                    {
                        unit: 'month',
                        align: 'center',
                        dateFormat: 'MMM YYYY'
                    },
                    {
                        unit: 'week',
                        align: 'center',
                        dateFormat: 'dd'
                    },
                    {
                        unit: 'day',
                        align: 'center',
                        dateFormat: 'DD'
                    }
                ]
            },
            {
                base: 'weekAndDay',
                id: 'weekAndDay2',
                tickWidth: 120,
                shiftUnit: 'day',
                headers: [
                    {
                        unit: 'month',
                        align: 'center',
                        dateFormat: 'MMM YYYY'
                    },
                    {
                        unit: 'd',
                        align: 'center',
                        dateFormat: 'DD'
                    },
                    {
                        unit: 'd',
                        align: 'center',
                        dateFormat: 'DD'
                    }
                ]
            }
        ],
        viewPreset: "weekAndDay0",
        timeResolution: {
            unit: 'hour',  //Valid values are "millisecond", "second", "minute", "hour", "day", "week", "month", "quarter", "year".
            increment: 1
        },
        rowHeight: 35,
        barMargin: 0,
        //stickyEventsFeature: false,
        eventTooltipFeature: {
            template: data => {
                function getEventStatus() {
                    let arrayStatusText = [];
                    data.eventRecord.isArchive && arrayStatusText.push(`archive event`);
                    return !isEmptyArray(arrayStatusText) ? `<div><b>Status:</b> ${arrayStatusText.join(", ")}</div>` : ""
                }

                function getTeamName(id) {
                    const teams = store.getState()?.calendarConfig?.projectTeams;
                    const currentTeam = teams.filter(item => item.projectGid == id)
                    if(currentTeam.length > 0) return  currentTeam[0].teamName
                    return id
                }

                function getTemplateInfo() {
                    const templatesByGame = store.getState()?.calendarConfig?.templates;
                    let liveopsTemplates = templatesByGame?.find(config => config.game === game && config.type === EVENT_TYPE.EVENT)?.templateGidsByName
                    let releaseTemplates = templatesByGame?.find(config => config.game === game && config.type === EVENT_TYPE.RELEASE)?.templateGidsByName
                    if (data.eventRecord.type === EVENT_TYPE.EVENT) return getLiveOpsTemplateInfoNameIfExist(liveopsTemplates, data.eventRecord);
                    if (data.eventRecord.type === EVENT_TYPE.RELEASE) return getLiveOpsTemplateInfoNameIfExist(releaseTemplates, data.eventRecord);
                    if (data.eventRecord.type === EVENT_TYPE.COMMUNITY) return getCommunityTemplateInfoNameIfExist(COMMUNITY_TEMPLATES, data.eventRecord)
                    return !isExists(data.eventRecord?.template)?  "no template": "unknown template"
                }

                return `<div>
                ${getEventStatus()}
                ${(data.eventRecord.name !== undefined) ? `<div><b>Name</b>: ${(data.eventRecord.name) + ((data.eventRecord.parentRelease !== undefined && data.eventRecord.parentRelease !== null) ? "%" : "")}</div>` : ''}
                ${(data.eventRecord.eventId !== undefined && data.eventRecord.eventId !== null && data.eventRecord.eventId.length !== 0) ? `<div><b>Event ID</b>: ${data.eventRecord.eventId}</div>` : ''}
                <div><b>Template name</b>: ${(getTemplateInfo())}</div>
                ${data.eventRecord?.originalData?.teamProject!=undefined && data.eventRecord?.originalData?.teamProject!='' ? `<div><b>Team</b>: ${getTeamName(data.eventRecord?.originalData?.teamProject)}</div>` : ''}
                ${(data.eventRecord.levelsWW !== undefined && data.eventRecord.levelsWW !== null && data.eventRecord.levelsWW.length !== 0) ? `<div><b>Levels WW</b>: ${data.eventRecord.levelsWW}</div>` : ''}
                ${(data.eventRecord.levelsBR !== undefined && data.eventRecord.levelsBR !== null && data.eventRecord.levelsBR.length !== 0) ? `<div><b>Levels BR</b>: ${data.eventRecord.levelsBR}</div>` : ''}
                ${(data.eventRecord.levelsPL !== undefined && data.eventRecord.levelsPL !== null && data.eventRecord.levelsPL.length !== 0) ? `<div><b>Levels PL</b>: ${data.eventRecord.levelsPL}</div>` : ''}
                ${(data.eventRecord.persent !== undefined && data.eventRecord.persent !== null) ? `<div><b>Persent</b>: ${data.eventRecord.persent}%</div>` : ''}
                ${(data.eventRecord.countries !== undefined && data.eventRecord.countries !== null && data.eventRecord.countries.length !== 0) ? `<div><b>Countries</b>: ${data.eventRecord.countries}</div>` : ''}
                ${(data.eventRecord.note !== undefined && data.eventRecord.note !== null && data.eventRecord.note.length !== 0) ? `<div><b>Note</b>: ${data.eventRecord.note}</div>` : ''}
        </div>`
            }
        },
        dependenciesFeature: {
            allowCreate: false
        },
        dependencyEditFeature: false,
        labelsFeature,
        eventDragCreateFeature: false,

        //disable delete row resource
        cellMenuFeature: {
            disabled: true
        },
        timeRangesFeature: {
            showCurrentTimeLine : {name: "now", cls: "current-time-line"},
            showHeaderElements  : true,
            tooltipTemplate({ timeRange }) {
                if (timeRange?.data?.info) {
                    return `${timeRange.data.info}`
                }
            }
        },
        nonWorkingTimeFeature: true,
        syncMask: null,
        startDate: startDate,
        endDate: endDate,
        eventRenderer({eventRecord, renderData, tplData}) {
            if (eventRecord.data.type === EVENT_TYPE.MATCH3 && eventRecord.data.isParentCompositeEvent && eventRecord.data.link && eventRecord.data.link !==''){
                tplData.style = 'display: none'
            }
            if (eventRecord.data.type === EVENT_TYPE.IMPORTANT_DATES) {
                addImportantDatesTimeRange(scheduler, eventRecord)
            }
            if (eventRecord.parentRelease !== undefined && eventRecord.parentRelease !== null) {
                renderData.eventStyle = 'line'
            }
            if (eventRecord.note !== undefined && eventRecord.note.length > 0) {
                renderData.iconCls = "b-fa b-fa-fw b-fa-sticky-note"
            }
            return eventRecord.duration !== 1 ? {
                name: getEventName(eventRecord) || "",
                mongoStatus: eventRecord.mongoStatus || "",
                type: eventRecord.type,
                duration: eventRecord.duration,
                note: eventRecord.note
            } : {
                name: "",
                mongoStatus: eventRecord.mongoStatus || "",
                type: eventRecord.type,
                duration: eventRecord.duration,
            }

        },

        eventBodyTemplate: data => {
            function iconByMongoStatus() {

                if (data.duration !== 1) {
                    switch (data.mongoStatus) {
                        case "CREATED":
                            return "b-fa b-fa-plus"
                        case "UPDATED":
                            return "b-fa b-fa-pen"
                        default:
                            return ""
                    }
                }
            }

            if (data.duration !== 1) {
                return `
                <div class = "b-sch-event-header">${data.name ? data.name : ""}</div>

                <ul  class = "b-sch-event-footer">
                ${["CREATED", "UPDATED"].includes(data.mongoStatus) ? `<li title="${data.mongoStatus}" class="${iconByMongoStatus()}"></li>` : ''}
                ${hasText(data.note) ? `<li title="Has note" class="b-fa b-fa-fw b-fa-sticky-note"></li>` : ''}
                </ul>
            `
            } else {
                return `
                    <div class = "b-sch-event-header">${data.name ? data.name : ""}</div>
                    ${["CREATED", "UPDATED"].includes(data.mongoStatus) ?
                    `<ul  class = "b-sch-event-footer"><li title="${data.mongoStatus}" class="${iconByMongoStatus()}"></li></ul>` : ""}
                `
            }
        },
        crudManager: {
            autoLoad: true,
            autoSync: false,
            autoCommit: true,
            phantomIdField: "phantomId",
            writeAllFields: true,
            transport: {
                load: {
                    url: `/configs?game=${game.toLowerCase()}`,

                    method: 'GET',
                }
            },
            eventStore:
                {
                    modelClass: BryntumEvent,
                    listeners: {
                        beforeAdd: (event) => {
                            // For Copy/Paste feature
                            const eventRecord = event.records[0];
                            if (![EVENT_TYPE.PROMO, EVENT_TYPE.PUSH, EVENT_TYPE.ASO].includes(eventRecord.type)) {
                                if (isExists(eventRecord.resource)) {
                                    const prevResource = eventRecord.resource.data.id
                                    const newResource = eventRecord.data.resourceId

                                    if (prevResource === newResource) {
                                        setStylesForNewEvents(eventRecord, eventRecord.resource)
                                        let days  = getRangeDayForCopyEvent(eventRecord, event.source.eventStore)
                                        eventRecord.startDate = DateHelper.add(eventRecord.startDate, days, 'd')
                                        if (isExists(eventRecord.copiedTime)) {
                                            DateHelper.set(eventRecord.startDate, "h", eventRecord.copiedTime);
                                            DateHelper.set(eventRecord.endDate, "h", eventRecord.copiedTime);
                                        }
                                        eventRecord.link = null
                                        sendAddEvent(eventRecord, game.toLowerCase())
                                        return true
                                    }
                                }
                            }
                        }
                    }
                },
            resourceStore: {
                fields: ["resourceHexColor"]
            },
            listeners: {
                requestDone: ({response}) => {
                    console.log("requestDone", response)
                    console.log("event", eventFromUrl)
                    if (eventFromUrl) {
                        const eventRecord = scheduler.current.schedulerInstance.eventStore.getById(eventFromUrl.trim())
                        const resourceRecord = scheduler.current.schedulerInstance.resourceStore.getById(eventRecord.data.resourceId)

                        if (eventRecord) {
                            console.log("data from url", eventFromUrl)
                            console.log(typeof eventRecord)
                            console.log("eventRecord", eventRecord.data)
                            console.log("resourceRecord", resourceRecord.data.name)

                            //scheduler.current.schedulerInstance.scrollResourceEventIntoView(resourceRecord, eventRecord)
                            scheduler.current.schedulerInstance.selectEvent(eventRecord)
                            scheduler.current.schedulerInstance.scrollEventIntoView(eventRecord)
                            //scheduler.current.schedulerInstance.selectedEvents = [eventRecord]
                        }
                    }
                    if (isExists(scheduler.current)) {
                        //попробовать тут фокусировку
                       scheduler.current.schedulerInstance.zoomToLevel(
                            scheduler.current.schedulerInstance.viewPreset.data.parentIndex
                        )

                        // TODO: Update with websockets
                        if (response.hasOwnProperty("requestId")) {
                            store.dispatch(removeSyncResourcesAction(response.requestId))
                        }
                    }
                },
                load: () => {
                    if (isExists(scheduler.current)) {
                        Mask.mask("Соединение... Это может занять до 20 сек.")
                        store.dispatch({type: INIT_WEBSOCKET, payload: {scheduler: scheduler.current.schedulerInstance, game: game.toLowerCase()}})
                        getAsanaSyncTasksInfo(game.toLowerCase(), scheduler.current.schedulerInstance)
                            .then(() => Mask.unmask())
                        getAsanaSyncTasksProgress(game.toLowerCase())

                    }

                }
            }
        }
    };
    return schedulerConfig;
}