import {EVENT_TYPE} from "./constants";
import {utcToZonedTime } from "date-fns-tz";


export const isExists = (value) => value !== undefined && value !== null
export const isNotNull = (value) =>  value !== null

export const allExists = (...values) => values.every(isExists)

export const isBoolean = (value) =>
    isExists(value)
    && typeof value === "boolean"

export const isObject = (value) =>
    isExists(value)
    && typeof value === "object"

export const isString = (value) =>
    isExists(value)
    && typeof value === "string"

export const propertyExists = (object, key) =>
    allExists(object, key)
    && isObject(object)
    && isString(key)
    && object.hasOwnProperty(key)
    && isExists(object[key])

export const propertyIsArray = (object, key) =>
    propertyExists(object, key)
    && Array.isArray(object[key])

export const propertyIsBoolean = (object, key) =>
    propertyExists(object, key)
    && isBoolean(object[key])

export const propertyIsObject = (object, key) =>
    propertyExists(object, key)
    && isObject(object[key])

export const isInstanceOf = (value, clazz) =>
    allExists(value, clazz)
    && value instanceof clazz

export const isEmptyArray = (value) =>
    Array.isArray(value)
    && value.length === 0

export const hasText = (value) =>
    isString(value)
    && value.trim().length > 0

export const isDevelopment = () => !process.env.NODE_ENV || process.env.NODE_ENV === "development"

export const isLocal = () => window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"

export const setHoursForEventDates = (eventRecord, hours, endHours) => {
    eventRecord.startDate = new Date(eventRecord.startDate.setHours(hours));
    eventRecord.endDate = new Date(eventRecord.endDate.setHours(endHours==undefined?0:endHours));
}

export const decodeBase64 = (encodedStr) => {
    return Buffer.from(encodedStr, 'base64').toString()
}

export const setFeaturingType = (eventRecord) => {
    if (decodeBase64(eventRecord.resourceId) === 'Featuring') {
        eventRecord.type = 'featuring'
    }
}


export const getEventName = (eventRecord) => {
    if (eventRecord.type === undefined ||
        eventRecord.type === null ||
        eventRecord.type.trim() === "" ||
        eventRecord.type !== EVENT_TYPE.AB_TEST)
    {
        if (eventRecord.autorun !== undefined && eventRecord.autorun !== null && eventRecord.autorun) {
            return "&#128260; "  + eventRecord.name;
        } else {
            return eventRecord.name;
        }
    }

    if (eventRecord.type === EVENT_TYPE.AB_TEST) {
        if (!eventRecord.isQACompleted && checkEventIsStarted(eventRecord)) {
            return "&#128205; " + eventRecord.name;
        }
    }
    return eventRecord.name;
}

export const checkEventIsStarted = (eventRecord) => {
    if (eventRecord.startDate === undefined ||
        eventRecord.startDate === null) return false;

    let startDate = new Date(eventRecord.startDate);
    return startDate <= new Date();


}

export function getDateInServerTimeZone(date) {
    if (date !== null) {
        const timeZone = new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
        return utcToZonedTime(date, timeZone);
    } else {
        return new Date();
    }
}


export const getEventIdFromParam = (searchParam) => {
    if (!searchParam) return null;
    if (searchParam.get('event') != null) {
        return searchParam.get('event')
    }
    return null;
}
